import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';

import App from '~/components/app';
import ErrorBoundary from '~/components/error/error-boundary';
import { initExceptionTracking } from '~/utils/exception-tracking';
import { appQueryClient } from '~/utils/configure-query-client';

import '~/utils/request-idle-callback-shim';

import '~/styles/index.scss';

main();

async function main() {
  initExceptionTracking();
  renderApp();
}

function renderApp() {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <ErrorBoundary>
      <BrowserRouter>
        <QueryClientProvider client={appQueryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
