import React from 'react';
import { default as ReactTooltipLite } from 'react-tooltip-lite';

import styles from '~/components/tooltip/tooltip.module.scss';

const Tooltip = ({ content, children, ...rest }) => {
  if (!content) {
    return children;
  }

  return (
    <span className={styles.container}>
      <ReactTooltipLite
        content={content}
        tipContentHover
        hoverDelay={400}
        mouseOutDelay={200}
        arrowSize={6}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <svg
            className={styles.icon}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
          >
            <path
              fill="var(--gray-color)"
              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
            />
            <path
              fill="var(--light-color)"
              d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
            />
          </svg>
        )}
      </ReactTooltipLite>
    </span>
  );
};

export default Tooltip;
