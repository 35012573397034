import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import AppHeader from '~/components/navigation/app-header';
import SideNav from '~/components/navigation/side-nav';
import Fallback from '~/components/fallback';
import PublicOnlyRoute from '~/components/public-only-route';
import AuthRoute from '~/components/auth-route';
import ErrorBoundary from '~/components/error/error-boundary';
import * as r from '~/routes';
import { safeLazy } from '~/utils/safe-lazy';
import { useSession } from '~/features/session/session-actions';
import { api } from '~/api';
import { NEW_MAPPING_TOOL } from '~/features/feature-flag';

const SignInPage = safeLazy(() =>
  import('~/components/user-management/sign-in-page')
);

const UsersIndexPage = safeLazy(() =>
  import('~/components/users/users-index-page')
);
const UserDetailsPage = safeLazy(() =>
  import('~/components/users/details/user-details-page')
);
const UserEmailRedirectPage = safeLazy(() =>
  import('~/components/users/user-email-redirect-page')
);

const LawnDetailsPage = safeLazy(() =>
  import('~/components/lawns/details/lawn-details-page')
);

const MappingToolPage = safeLazy(() =>
  import('~/components/mapping-tool/page/mapping-tool-page')
);

const ObligationsPage = safeLazy(() =>
  import('~/components/obligations/obligations-page')
);

const UserShipmentsPage = safeLazy(() =>
  import('~/components/user-shipments/user-shipments-page')
);

const ShipmentsPage = safeLazy(() =>
  import('~/components/shipments/shipments-page')
);

const SubscriptionsPage = safeLazy(() =>
  import('~/components/subscriptions/subscriptions-page/subscriptions-page')
);

const StoreItemsPage = safeLazy(() =>
  import('~/components/store-items/store-items-page')
);

const PlanTasksPage = safeLazy(() =>
  import('~/components/plan-tasks/plan-tasks-page')
);

const ImageComparisonPage = safeLazy(() =>
  import('~/components/image-comparison/image-comparison-page')
);

const Playground = safeLazy(() => import('~/components/playground'));
const NotFoundPage = safeLazy(() => import('~/components/not-found-page'));
const FlashMessages = safeLazy(() =>
  import('~/components/messages/flash-messages')
);
const ModalSelector = safeLazy(() =>
  import('~/components/modals/modal-selector')
);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { initSession, isInitializing, signOut } = useSession();

  // Scroll to top of page on route change
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    initSession();
    api.setErrorListener((response) => {
      if (response.status === 401) {
        signOut();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to the /users route from /
  useEffect(() => {
    if (location.pathname === '/') {
      navigate(r.usersRoute());
    }
  }, [location.pathname, navigate]);

  if (isInitializing) {
    return <Fallback />;
  }

  return (
    <>
      <Suspense fallback={<Fallback />}>
        {/* App routes are split out to allow for a common app shell/chrome */}
        <Routes>
          <Route
            element={
              <PublicOnlyRoute>
                <SignInPage />
              </PublicOnlyRoute>
            }
            path={r.signInRoute()}
          />

          {NEW_MAPPING_TOOL && (
            <Route element={<MappingToolPage />} path={r.mappingToolRoute()} />
          )}

          <Route element={<AppLayoutRoutes />} path="*" />
        </Routes>
      </Suspense>

      <Suspense fallback={null}>
        <FlashMessages />
        <ModalSelector />
      </Suspense>
    </>
  );
};

export default App;

function AppLayoutRoutes() {
  return (
    <div className="appLayoutContainer">
      <SideNav />

      <AppHeader />

      <main className="main">
        <ErrorBoundary>
          <Suspense fallback={<Fallback />}>
            <Routes>
              {/* Users */}
              <Route
                element={
                  <AuthRoute>
                    <UsersIndexPage />
                  </AuthRoute>
                }
                path={r.usersRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <UserDetailsPage />
                  </AuthRoute>
                }
                path={r.userDetailsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <UserEmailRedirectPage />
                  </AuthRoute>
                }
                path={r.userEmailRedirectRoute()}
              />

              <Route
                element={
                  <AuthRoute>
                    <LawnDetailsPage />
                  </AuthRoute>
                }
                path={r.lawnDetailsRoute()}
              />

              {/* Obligations */}
              <Route
                element={
                  <AuthRoute>
                    <ObligationsPage />
                  </AuthRoute>
                }
                path={r.obligationsRoute()}
              />

              {/* Shipments */}
              <Route
                element={
                  <AuthRoute>
                    <UserShipmentsPage />
                  </AuthRoute>
                }
                path={r.userShipmentsRoute() + '/*'}
              />

              <Route
                element={
                  <AuthRoute>
                    <ShipmentsPage />
                  </AuthRoute>
                }
                path={r.shipmentsRoute()}
              />

              {/* Subscriptions */}
              <Route
                element={
                  <AuthRoute>
                    <SubscriptionsPage />
                  </AuthRoute>
                }
                path={r.subscriptionsRoute() + '/*'}
              />

              {/* Store Items */}
              <Route
                element={
                  <AuthRoute>
                    <StoreItemsPage />
                  </AuthRoute>
                }
                path={r.storeItemsRoute()}
              />

              {/* Plan Tasks */}
              <Route
                element={
                  <AuthRoute>
                    <PlanTasksPage />
                  </AuthRoute>
                }
                path={r.planTasksRoute()}
              />

              <Route
                element={
                  <AuthRoute>
                    <ImageComparisonPage />
                  </AuthRoute>
                }
                path={r.imageComparisonRoute()}
              />

              <Route element={<Playground />} path="/playground" />
              <Route element={<NotFoundPage />} path="*" />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </main>
    </div>
  );
}
