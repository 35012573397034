export const productsApi = (http) => {
  return {
    purchaseSkus: async () => {
      const res = await http.get(
        `/api/v2/purchase_skus?active=True&in_stock=True&limit=2000`
      );
      return res.results.sort((a, b) => (a.title < b.title ? -1 : 1));
    },
  };
};
