// Event Types
const TRIGGERED_LAWN_ENGINE = 'triggered_lawn_engine'

export const eventsApi = (http) => ({
    getLawnEngineEvents: async (lawnUuid) => {
        const lawnEngineEventsRes = await http.get(`/api/audit/events/?eventType=${TRIGGERED_LAWN_ENGINE}&lawnUuid=${lawnUuid}`)
        // TODO: handle paginated responses
        return {
            events: lawnEngineEventsRes.results
        };
    }
})