import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { usersRoute } from '~/routes';
import { useSession } from '~/features/session/session-actions';

const DEFAULT_REDIRECT = usersRoute();

const PublicOnlyRoute = ({ children }) => {
  const location = useLocation();
  const { currentUser } = useSession();
  const [shouldRedirect, setShouldRedirect] = useState(Boolean(currentUser));
  const queryParams = queryString.parse(location.search.substring(1));
  const redirectPath = queryParams.redirect || DEFAULT_REDIRECT;

  /**
   * This is weird and I'm sorry.
   *
   * Instead of reading directly from props.isSignedIn to determine
   * if we should redirect we're using a state variable called `shouldRedirect`
   * that is updated to mimic props.isSignedIn after a short delay.
   * This fixes a strange bug (probably with react-spring) where remnants
   * of the sign in page will "stick" on the page when redirecting to certain
   * other pages.
   *
   * Steps to verify if this is still needed:
   * 1. Sign in with a `?redirect=` query param of something other than
   * the account page. Ex: ?redirect=%2Fcheckout.
   * 2. When redirected if there are parts of the sign in page still rendered
   * on the page then this is still needed.
   */
  useEffect(() => {
    const id = setTimeout(() => {
      setShouldRedirect(Boolean(currentUser));
    }, 100);

    return () => clearTimeout(id);
  }, [currentUser]);

  if (shouldRedirect) {
    return <Navigate to={redirectPath} />;
  } else {
    return children;
  }
};

export default PublicOnlyRoute;
