import React, { useContext } from 'react';

import { FormContext } from './form';

const Field = ({
  name,
  label,
  disabled,
  component: Component,
  children,
  onChange: externalOnChange,
  format,
  ...rest
}) => {
  const { state, handleFieldChange } = useContext(FormContext);

  const value = state.formValues[name] || '';
  const error = state.formErrors[name];

  const componentProps = {
    label,
    children,
    input: {
      ...rest,
      name,
      value,
      checked: value,
      disabled: disabled || state.isSubmitting,
      onChange: (e) => {
        let newValue;
        if (e.target.type === 'checkbox') {
          newValue = e.target.checked;
        } else {
          newValue = e.target.value;
        }

        if (format) {
          newValue = format({ newValue, oldValue: value });
        }

        handleFieldChange(name, newValue);
      },
      'data-valid': Boolean(!error),
    },
    meta: {
      error,
      submitFailed: state.submitFailed,
    },
  };

  return <Component {...componentProps} />;
};

export default Field;
