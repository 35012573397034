import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

/**
 * Holds an array of Message objects:
 *
 * type Message = {
 *    text: string;
 *    variant: 'error' | 'success' | 'info'
 * }
 */
const flashMessagesAtom = atom([]);

export const useFlashMessage = () => {
  const [messages, setMessages] = useAtom(flashMessagesAtom);

  const hideFlashMessage = useCallback(
    (text) => {
      setMessages((prev) => prev.filter((message) => message.text !== text));
    },
    [setMessages]
  );

  const showFlashMessage = useCallback(
    (text, variant = 'error', duration = 6000) => {
      const message = { text, variant };
      setMessages((prev) => [...prev, message]);

      return new Promise((resolve) => {
        setTimeout(() => {
          hideFlashMessage(text);
          resolve();
        }, duration);
      });
    },
    [hideFlashMessage, setMessages]
  );

  return {
    showFlashMessage,
    hideFlashMessage,
    messages,
  };
};
