import React from 'react';
import cx from 'classnames';

import styles from './inputs.module.scss';

const SearchField = (props) => {
  const hasError = props.meta.submitFailed && props.meta.error;

  const inputClasses = cx(
    styles.input,
    styles.searchInput,
    props.input.className,
    {
      [`${styles.inputError}`]: hasError,
    }
  );

  return (
    <div style={{ position: 'relative' }}>
      <img className={styles.searchIcon} src="/icons/search.svg?v1" alt="" />

      <input
        {...props.input}
        id={props.input.name}
        className={inputClasses}
        disabled={props.input.disabled}
        aria-label={props.label}
      />
    </div>
  );
};

export default SearchField;
