import queryString from 'query-string';

import { CancellationType } from '~/features/subscriptions/subscription';

const typeToDescription = {
  [CancellationType.CANCEL_NOW]:
    'Cancels the subscription immediately so the user does not receive any more shipments',
  [CancellationType.DO_NOT_RENEW]:
    'Cancels the next scheduled subscription, but keeps the current one active so the user receives any remaining shipments',
  [CancellationType.FREE_PLAN]:
    'Cancels the next scheduled subscription, but keeps the current one active so the user receives any remaining shipments',
};

export const subscriptionsApi = (http) => {
  return {
    update: async (subscriptionUuid, updatedFields) => {
      /** `updatedField` can contain:
       * `name` - the name of the subscription (string)
       * `subscriptionItems` - array of objects that each contain: `sku_id` (string) and `quantity` (integer)
       */
      return http.patch(
        `/admin/subscriptions/${subscriptionUuid}`,
        updatedFields
      );
    },

    cancellationOptions: async () => {
      const res = await http.get('/admin/subscriptions/choices/');

      return {
        types: res.cancellationTypes.map(([name, label]) => {
          return {
            name,
            label,
            description: typeToDescription[name] || null,
          };
        }),
        reasons: res.cancellationReasons
          .map(([name, label]) => {
            return {
              name,
              label,
            };
          })
          .sort(sortByLabel),
      };
    },

    forLawn: async (lawnUuid) => {
      return http.get(`/admin/lawns/${lawnUuid}/subscriptions`);
    },

    cancelSubscription: async ({
      cancellationReason,
      notes,
      subscriptionUuid,
      keepPendingShipments,
    }) => {
      const queryParams = queryString.stringify({
        cancellation_reason: cancellationReason,
        notes,
        keep_scheduled_deliveries: keepPendingShipments,
      });

      await http.delete(
        `/admin/subscriptions/${subscriptionUuid}?${queryParams}`
      );

      return null;
    },

    cancelSubscriptionItem: async ({
      subscriptionItemUuid,
      cancellationReason,
      notes = '',
    }) => {
      await http.patch(
        `/admin/subscription-item/${subscriptionItemUuid}/cancel`,
        {
          cancellation_reason: cancellationReason,
          notes,
        }
      );

      return null;
    },

    attemptReauthorization: async (userUuid) => {
      return await http.post('/api/carts/', {
        reAuth: true,
        customerUuid: userUuid,
      });
    },
  };
};

function sortByLabel(a, b) {
  if (a.label < b.label) {
    return -1;
  } else {
    return 1;
  }
}
