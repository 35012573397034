import queryString from 'query-string';

export const usersApi = (http) => {
  return {
    all: async (filters = {}) => {
      const query = queryString.stringify(filters);
      const res = await http.get(`/admin/users/?${query}`);

      return {
        count: res.count,
        results: res.results,
      };
    },

    uuidFromEmail: async (email) => {
      const res = await http.get(
        `/admin/users/?${queryString.stringify({ email })}`
      );

      return res.results[0]?.uuid;
    },

    find: async (userUuid) => {
      const res = await http.get(`/admin/users/${userUuid}/`);
      return parseUser(res);
    },

    findForSignIn: async (email, options) => {
      const res = await http.get(
        `/api/users/${encodeURIComponent(email)}/`,
        options
      );
      return res;
    },

    update: async ({ userUuid, user }) => {
      return http.patch(`/admin/users/${userUuid}/`, user);
    },

    adjustStoreCredit: async ({ userUuid, amountInDollars, notes, source }) => {
      return http.post(`/api/credit_grants/`, {
        userUuid,
        // the only decimal places after converting to cents
        // should be floating point precision errors, so round those off
        amount: Math.round(amountInDollars * 100),
        notes,
        source,
      });
    },

    getStoreCreditHistory: async (userUuid) => {
      return http.get(`/api/store_credits/${userUuid}/`);
    },

    generateMagicLink: async (email) => {
      return http.post('/admin/magic_link/', {
        email,
        usage: 'rea', // "reauth"
        query: { redirect: '/account/settings' },
      });
    },
  };
};

function parseUser(res) {
  const shippingAddress = res.shippingAddress || {};

  return {
    ...res,
    stripeCustomerId: res.customerId,
    shippingAddress: {
      ...shippingAddress,
      uuid: shippingAddress.uuid,
    },
  };
}
