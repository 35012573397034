export const ordersApi = (http) => {
  return {
    forUser: async (userUuid) => {
      const res = await http.get(`/admin/users/${userUuid}/purchases/`);
      return parseOrders(res, userUuid);
    },

    issueRefund: ({ userUuid, orderUuid, refund }) => {
      return http.post(
        `/admin/users/${userUuid}/purchases/${orderUuid}/refunds/`,
        refund
      );
    },

    refundReasons: () => {
      return http.get('/api/refund-options/');
    },

    forUserV2: async (userUuid) => {
      const res = await http.get(
        `/api/v2/purchase_orders/?user_uuid=${userUuid}&isPurchased=true`
      );
      return res;
    },

    applyDefaultShippingAddress: async (userUuid) => {
      return http.put(
        `/admin/users/${userUuid}/apply_default_shipping_address/`
      );
    },
    updateProcessDate: async (orderUuid, processDate) => {
      return http.patch(`/api/carts/${orderUuid}/process-date/`, {
        processDate,
      });
    },
  };
};

function parseOrders(res, userUuid) {
  return res.map((order) => {
    return {
      ...order,
      userUuid,
      stripeChargeId: order.chargeId,
    };
  });
}
