import { set, get, del } from 'idb-keyval';

/**
 * createStorage() is a factory function that returns an object with methods
 * for interacting with device storage.
 */
export const createStorage = ({ dataStore, namespace }) => {
  const getFullKey = (key) => {
    return `${namespace}:${key}`;
  };

  return {
    get: (key) => {
      return dataStore.get(getFullKey(key));
    },

    set: (key, value) => {
      return dataStore.set(getFullKey(key), value);
    },

    remove: (key) => {
      return dataStore.del(getFullKey(key));
    },
  };
};

export const storage = createStorage({
  dataStore: {
    get,
    set,
    del,
  },
  namespace: 'GARDEN_CENTER',
});

(window.gardenCenter = window.gardenCenter || {}).storage = storage;
