import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { DropdownContext } from '~/components/dropdown/dropdown';

import styles from '~/components/dropdown/dropdown.module.scss';

const DropdownItem = ({ to, icon, onClick, children, ...rest }) => {
  const context = useContext(DropdownContext);

  const handleClick = (...args) => {
    if (onClick) {
      onClick(...args);
    }

    context?.close();
  };

  if (to) {
    return (
      <Link
        to={to}
        className={styles.dropdownItem}
        onClick={handleClick}
        {...rest}
      >
        {icon && <img className={styles.dropdownItemIcon} src={icon} alt="" />}
        <span className={styles.dropdownItemContent}>{children}</span>
      </Link>
    );
  }

  return (
    <button
      className={styles.dropdownItem}
      type="button"
      onClick={handleClick}
      {...rest}
    >
      {icon && <img className={styles.dropdownItemIcon} src={icon} alt="" />}
      <span className={styles.dropdownItemContent}>{children}</span>
    </button>
  );
};

export default DropdownItem;
