/**
 * Utility function for creating an async task that resolves
 * after a specified duration.
 */
export const wait = (ms = 0) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

/**
 * Run an async task for at least as long as `waitForDuration`.
 * Useful for loading pages where we want an action to take at least x seconds.
 */
export const runForAtLeast = (fn, waitDuration = 0) => {
  return Promise.all([fn(), wait(waitDuration)]).then(([res]) => res);
};

// Adopted from the canonical David Walsh post
// https://davidwalsh.name/javascript-debounce-function
export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const callNow = immediate && !timeout;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const throttle = (func, limit) => {
  let inThrottle;

  return function () {
    const args = arguments;
    const context = this;

    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

// https://github.com/moroshko/shallow-equal/blob/master/src/objects.js
export const shallowEqualObjects = (objA, objB) => {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  var aKeys = Object.keys(objA);
  var bKeys = Object.keys(objB);
  var len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (var i = 0; i < len; i++) {
    var key = aKeys[i];

    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
};

export const trimValues = (obj) => {
  const trimmed = {};

  for (const propertyName in obj) {
    let value = obj[propertyName];

    if (typeof value !== 'string') {
      trimmed[propertyName] = value;
      continue;
    }

    if (value === undefined || value === null) {
      value = '';
    }

    trimmed[propertyName] = value.trim();
  }

  return trimmed;
};

/**
 * Push a new item to the front of `arr`
 * and enforce uniqueness on the result
 */
export const shiftArrayUnique = (arr, item) => {
  return unique([item, ...arr]);
};

export const unique = (arr) => {
  return [...new Set(arr)];
};

export const retryPromise = (fn, retriesLeft = 2, interval = 500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const equalArrays = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const sortedA = [...a].sort();
  const sortedB = [...b].sort();

  for (let i = 0; i <= a.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false;
    }
  }

  return true;
};

/**
 * Return the number if within the given bounds,
 * otherwise return the bounding value
 *
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 */
export const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

export const toSentenceCase = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};
