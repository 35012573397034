export const paymentsApi = (http) => {
  return {
    forUser: (userUuid) => {
      return http.get(`/api/payments/?user_uuid=${userUuid}`);
    },

    forPurchaseOrder: (purchaseOrderUuid) => {
      return http.get(
        `/api/payments/?purchase_order_uuid=${purchaseOrderUuid}`
      );
    },

    sendReceipt: (purchaseOrderUuid) => {
      return http.post('/api/payment-receipt/', {
        purchase_order_uuid: purchaseOrderUuid,
      });
    },

    deletePaymentMethod: (paymentMethodId) => {
      return http.delete(`/api/payment-method/${paymentMethodId}/`);
    },
  };
};
