import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import LoadingIndicator from '~/components/loading-indicator';

import styles from './button.module.scss';

const Button = React.forwardRef(
  (
    {
      className,
      fullWidth,
      variant = 'primary',
      size,
      to,
      href,
      isLoading,
      ...rest
    },
    ref
  ) => {
    const classes = cx(styles.button, className, {
      [`${styles.block}`]: fullWidth,
      [`${styles.primary}`]: variant === 'primary',
      [`${styles.outline}`]: variant === 'outline',
      [`${styles.link}`]: variant === 'link',
      [`${styles.error}`]: variant === 'error',
      [`${styles.small}`]: size === 'small',
    });

    if (to) {
      return <Link to={to} className={classes} {...rest} ref={ref} />;
    }

    if (href) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a href={href} className={classes} {...rest} ref={ref} />;
    }

    return (
      <button className={classes} disabled={isLoading} {...rest} ref={ref}>
        {isLoading ? <LoadingIndicator /> : rest.children}
      </button>
    );
  }
);

export default Button;
