export const obligationsApi = (http) => {
  return {
    forUser: async (userUuid) => {
      const res = await http.get(
        `/api/v2/obligations/?user_uuid=${userUuid}&fulfillment_method=physical_product`
      );

      return res;
    },

    create: async (obligations) => {
      const res = await http.post(`/api/v2/obligations/`, obligations);

      return res;
    },

    update: async (obligation) => {
      const res = await http.patch(
        `/api/v2/obligations/${obligation.uuid}/`,
        obligation
      );

      return res;
    },
  };
};
