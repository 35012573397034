import { wait } from '~/utils/functions';
import { QUESTIONNAIRE } from '~/mocks/questionnaire-mocks';

export const questionnaireApi = (http) => {
  return {
    forUser: async (userUuid) => {
      await wait(500);
      return QUESTIONNAIRE;
    },
  };
};
