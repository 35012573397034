import React from 'react';

import styles from '~/components/environment-banner.module.scss';

const IS_VISIBLE = ['develop', 'staging'].includes(window.env.DEPLOY_ENV);

const EnvironmentBanner = () => {
  if (!IS_VISIBLE) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <p>
        Environment: <strong>{window.env.SUNDAY_ENV}</strong>
      </p>
    </div>
  );
};

export default EnvironmentBanner;
