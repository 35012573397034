import { QueryClient } from 'react-query';

export const configureQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 30,
      },
    },
  });
};

export const appQueryClient = configureQueryClient();
