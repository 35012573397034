import { ShipmentItemType } from '~/features/shipments/shipment-item';

export const ShipmentStatus = {
  BACKORDER: 'backorder', // Sent to shipping provider, but on backorder due to out of stock
  // ** This is intentionally UPPERCASE to match subsoil **
  CANCELLED: 'CANCELLED', // Happens when shipments don't transmit due to out of stock issues
  DELIVERED: 'delivered', // Sent to final address
  ERROR: 'error', // Shipment in any error status prior to successful transmit
  EXCEPTION: 'exception', // Shipment with a delivery exception after successful transmit
  FAILED: 'failed', // Shipment that has a permanent delivery failure for which we should not update
  HOLD: 'hold',
  NO_PAYMENT: 'no_payment', // Items not purchased yet
  OPEN: 'open', // Created in our DB, should not be transmitted to shipping provider
  PACKED: 'packed', // In process, can't be cancelled, updated, etc.
  PENDING: 'pending', // Not sent to shipping provider
  PRESUMED_DELIVERED: 'presumed_delivered',
  PROCESSING: 'processing', // In process, can be cancelled, updated, etc.
  SHIPPED: 'shipped', // Left shipping provider
  TRANSMITTED: 'transmitted', // Sent to shipping provider, not in process
  UNDELIVERED: 'undelivered',
  UNPAID: 'unpaid', // FlexPay - shipment is unpaid and will be charged & shipped at a later date
};

/**
 * Returns the year the shipment will be shipped
 */
export const getShipmentYear = (shipment) => {
  return new Date(derivedShipmentDate(shipment)).getFullYear();
};

/**
 * Return an object with all possible shipment years as keys
 * and an array of matching shipments as values
 */
export const groupShipmentsByYear = (shipments) => {
  if (!shipments) {
    shipments = [];
  }

  return shipments.reduce((byYear, shipment) => {
    const shipmentYear = getShipmentYear(shipment);

    if (!byYear[shipmentYear]) {
      byYear[shipmentYear] = [];
    }

    byYear[shipmentYear].push(shipment);

    return byYear;
  }, {});
};

/**
 * Return an object with `minYear` and `maxYear` properties
 * for the minimum and maximum possible shipment years
 */
export const getShipmentYearBounds = (shipments) => {
  if (!shipments) {
    shipments = [];
  }

  const byYear = groupShipmentsByYear(shipments);

  return {
    minYear: Math.min(...Object.keys(byYear)),
    maxYear: Math.max(...Object.keys(byYear)),
  };
};

/**
 * Determines if the shipment has any items with an itemType of 'subscription',
 * meaning it belongs to some kind of subscription purchase.
 */
export const shipmentHasSubscriptionItems = (shipment) => {
  return shipment?.items?.some(
    (si) => si.itemType === ShipmentItemType.SUBSCRIPTION
  );
};

/**
 * Determines if any shipment fields should be disabled while editing
 */
export const canEditShipment = (shipment) => {
  if (!shipment || !shipment.status) {
    return true;
  }

  return [
    ShipmentStatus.OPEN,
    ShipmentStatus.PENDING,
    ShipmentStatus.ERROR,
  ].includes(shipment.status);
};

export const canDeleteShipment = (shipment) => {
  // Shipments with a subscription item cannot be deleted
  const hasSubscriptionItems = shipmentHasSubscriptionItems(shipment);

  const hasValidStatus = [
    ShipmentStatus.OPEN,
    ShipmentStatus.PENDING,
    ShipmentStatus.ERROR,
  ].includes(shipment.status);

  return hasValidStatus && !hasSubscriptionItems;
};

/**
 * Determines if a shipment should be allowed to transmit to
 * a 3PL for processing
 */
export const canTransmitShipment = (shipment, lawn) => {
  // If the user has a lawn then a mapping error makes shipments non-transmittable
  const hasMappingError = Boolean(lawn?.mappingError);

  const hasValidStatus = [
    ShipmentStatus.OPEN,
    ShipmentStatus.UNPAID,
    ShipmentStatus.PENDING,
    ShipmentStatus.ERROR,
  ].includes(shipment.status);

  return !hasMappingError && hasValidStatus;
};

/**
 * Determines if a shipment is eligible to be split into two shipments
 * _and_ transmitted to the 3PL
 */
export const canSplitShipment = (shipment, lawn) => {
  const hasValidStatus = [
    ShipmentStatus.ERROR,
    ShipmentStatus.PENDING,
  ].includes(shipment?.status);

  const hasEnoughShipmentItems = shipment.items.length >= 2;

  const canTransmit = canTransmitShipment(shipment, lawn);

  return hasValidStatus && hasEnoughShipmentItems && canTransmit;
};

export const canRmaShipment = (shipment) => {
  return ![
    ShipmentStatus.NO_PAYMENT,
    ShipmentStatus.PENDING,
    ShipmentStatus.UNPAID,
    ShipmentStatus.TRANSMITTED,
    ShipmentStatus.PROCESSING,
    ShipmentStatus.ERROR,
  ].includes(shipment?.status);
};

// This is a temp fix until we remove the ability to create/clone shipments.
export const doesShipmentHavePurchaseOrder = (shipment) => {
  return shipment?.items
    ? shipment?.items?.filter(
        (item) => item?.temporaryHasPurchaseOrder === true
      ).length > 0
    : false;
};

export const doesShipmentHaveReplacementItem = (shipment) => {
  return shipment?.items?.some((item) => item.isReplacing);
};

/**
 * Find the next shipment to be delivered on or after the given date
 */
export const getNextShipmentAfter = (shipments, earliestDay) => {
  if (!shipments) {
    return null;
  }

  // shipments are already sorted by date, so we can
  // grab the first one that matches
  return shipments.find((shipment) => {
    return new Date(derivedShipmentDate(shipment)) >= earliestDay;
  });
};

/**
 * Determine the date to show for a given shipment.
 *
 * - shippedAtDate is the day the shipment left the warehouse
 * - transmittedAtDate is the day the shipment was actually sent to the 3PL
 * - targetShipmentDate is the day we plan to transmit to the 3PL
 *
 * If there is a date for when the shipment was actually shipped
 * then return it. Otherwise return the target shipment date.
 *
 * Note that topsoil adds additional logic to check for
 * estimatedShipmentDate and add a buffer to ERROR status shipments
 *
 * @returns {string | null} - ISO date string
 */
export const derivedShipmentDate = (shipment) => {
  if (!shipment) {
    return null;
  }

  if (shipment.shippedAtDate) {
    return shipment.shippedAtDate;
  }

  if (shipment.transmittedAtDate) {
    return shipment.transmittedAtDate;
  }

  return shipment.targetShipmentDate;
};
