import { SOIL_TEST_DESCRIPTIONS } from '~/features/lawns/lawn';

export const lawnsApi = (http) => {
  return {
    details: async (lawnUuid) => {
      const res = await http.get(`/admin/lawns/${lawnUuid}/lawn_info/`);
      return parseDetails(res);
    },

    summary: async (lawnUuid) => {
      const res = await http.get(`/admin/lawns/${lawnUuid}/`);
      return res;
    },

    fetchSoilTest: async (lawnUuid) => {
      const res = await http.get(`/admin/lawns/${lawnUuid}/soil_test_result/`);
      return parseSoilTest(res);
    },

    fetchDigitalSoilTest: async (lawnUuid) => {
      return await http.get(
        `/admin/prediction/lawn/${lawnUuid}/soil_properties/`
      );
    },

    update: async (lawnUuid, updatedLawn) => {
      const res = await http.patch(`/admin/lawns/${lawnUuid}/`, updatedLawn);
      return res;
    },

    uploadLawnImage: async (lawnUuid, image, filename) => {
      const formData = new FormData();
      formData.append('file', image, filename);

      // Instead of sending the payload as the second arg
      // we need to override the body so it's not JSON stringified.
      return http.post(`/admin/lawns/${lawnUuid}/lawn_image/`, null, {
        body: formData,
      });
    },

    updateAddress: async (lawnUuid, address) => {
      const res = await http.post(`/admin/lawns/${lawnUuid}/lawn_address/`, {
        lawnAddress: address,
      });
      return res;
    },

    updateInfo: async (lawnUuid, info) => {
      const res = await http.post(`/admin/lawns/${lawnUuid}/lawn_info/`, {
        info,
      });
      return res;
    },

    runLawnEngine: async (
      lawnUuid,
      pouchCount,
      sourceEngineOverride,
      renewalOnly = false,
      activeSubscriptionItemUuid
    ) => {
      let body = {};

      // if no activeSubscriptionItemUuid is provided, the BE queries the subscription item for the renewal year and runs the LE against that future subscription item
      if (activeSubscriptionItemUuid) {
        body.activeSubItemUuid = activeSubscriptionItemUuid;
      }

      if (pouchCount) {
        body.targetPouchCount = pouchCount;
      }

      if (sourceEngineOverride) {
        body.sourceEngineOverride = sourceEngineOverride;
      }

      if (renewalOnly) {
        body.renewalYearOnly = true;
      }

      const res = await http.post(
        `/admin/lawns/${lawnUuid}/run_lawn_engine/`,
        body
      );
      return parsePlanApplications(res.planApplications);
    },

    resetLawnPlan: async (customerUuid) => {
      await http.post('/admin/subscription/lawnplan/resize/', {
        customerUuid,
      });
    },
  };
};

function parseSoilTest(res) {
  if (Object.keys(res).length === 0) {
    return null;
  }

  const parseResult = (dataName) => {
    return {
      value: res[dataName].value,
      rating: res[dataName].rating,
      color: res[dataName].color,
      displayName: res[dataName].displayName,
      barLength: res[dataName].barLength,
      barText: res[dataName].barText,
      description: SOIL_TEST_DESCRIPTIONS[dataName],
    };
  };

  const parsed = {
    conditions: res.conditions,
    data: {
      'soil fertility': [
        parseResult('organicMatter'),
        parseResult('soilPh'),
        parseResult('cationExchange'),
        parseResult('nitrogenRelease'),
      ],
      macronutrients: [parseResult('phosphorus'), parseResult('potassium')],
      micronutrients: [
        parseResult('sulfur'),
        parseResult('calcium'),
        parseResult('magnesium'),
        parseResult('boron'),
        parseResult('iron'),
        parseResult('manganese'),
        parseResult('copper'),
        parseResult('zinc'),
      ],
      'soil salts': [parseResult('sodium')],
    },
    soilTestDate: res.soilTestDate,
    reportCard: {
      'fertility grade': {
        grade: res.fertilityGrade,
        data: [
          parseResult('organicMatter'),
          parseResult('soilPh'),
          parseResult('cationExchange'),
          parseResult('nitrogenRelease'),
        ],
      },
      'nutrient grade': {
        grade: res.nutrientGrade,
        data: [
          { ...parseResult('macronutrients'), displayName: 'macronutrients' },
          { ...parseResult('micronutrients'), displayName: 'micronutrients' },
        ],
      },
      'soil salt grade': {
        grade: res.soilSaltGrade,
        data: [parseResult('sodium')],
      },
    },
  };

  return parsed;
}

function parseDetails(res) {
  const planApplications = res.planApplications || [];

  return {
    ...res,
    lawnEngine: parsePlanApplications(planApplications),
  };
}

function parsePlanApplications(planApplications) {
  return planApplications.map((planApplication) => {
    const applications = planApplication.applications || [];

    return {
      updatedAt: planApplication.updatedAt,
      numberOfPouches: planApplication.numberOfPouches,
      numberOfApplications: planApplication.numberOfApplications,
      planType: planApplication.planType,
      planYear: planApplication.year,
      planLocked: planApplication.planLocked,
      applications: applications.map((application) => {
        return {
          applicationDate: application.startDate,
          pouchName: application.title,
          pouchesPerApplication: application.skuQuantity,
        };
      }),
    };
  });
}
