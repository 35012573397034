import React from 'react';
import cx from 'classnames';

import styles from './inputs.module.scss';

const SelectField = (props) => {
  const { variant, errorMessageClassName, ...inputProps } = props.input;
  const hasError = props.meta.submitFailed && props.meta.error;

  const wrapperClasses = cx(styles.selectWrapper, {
    [`${styles.frozenWrapper}`]: variant === 'frozen',
  });

  const inputClasses = cx(styles.input, props.input.className, {
    [`${styles.inputError}`]: hasError,
    [`${styles.frozen}`]: variant === 'frozen',
  });

  const labelClasses = cx(styles.label, {
    [`${styles.labelError}`]: hasError,
  });

  return (
    <div>
      {props.label && (
        <label htmlFor={props.input.name} className={labelClasses}>
          {props.label}
        </label>
      )}

      <div className={wrapperClasses}>
        <select {...inputProps} id={props.input.name} className={inputClasses}>
          {props.children}
        </select>
      </div>

      {hasError && (
        <p className={cx(styles.errorMsg, errorMessageClassName)}>
          {props.meta.error}
        </p>
      )}
    </div>
  );
};

export default SelectField;
