/**
 * Possible query parameter properties when using the user search in the header
 */
export const USER_SEARCH_KEYS = ['email', 'shipping_phone_number', 'lawn_address', 'name', 'user_slug'];

/**
 * Convert a UI phone number into the E164 format accepted by the API
 *
 * @param {string} phone - "(888) 555-1234"
 * @return {string} - "+18885551234"
 */
export const toApiPhone = (phone) => {
  if (!phone) {
    return '';
  }

  const countryCode = '+1';
  const strippedPhone = phone.replace(/\W*/g, '');

  return `${countryCode}${strippedPhone}`;
};

/**
 * Convert an API phone number into the UI-friendly format
 */
export const toUiPhone = (phone) => {
  if (!phone) {
    return '';
  }

  const withoutCountryCode = phone.charAt(0) === '+' ? phone.slice(2) : phone;

  if (withoutCountryCode.length !== 10) {
    return phone;
  }

  const areaCode = withoutCountryCode.slice(0, 3);
  const firstThree = withoutCountryCode.slice(3, 6);
  const lastFour = withoutCountryCode.slice(6);
  return `(${areaCode}) ${firstThree}-${lastFour}`;
};
