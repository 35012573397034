import React from 'react';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import cx from 'classnames';

const NavLink = ({ activeClassName, className, to, children, ...rest }) => {
  return (
    <ReactRouterNavLink
      to={to}
      className={({ isActive }) =>
        cx(className, {
          [`${activeClassName}`]: isActive,
        })
      }
      {...rest}
    >
      {children}
    </ReactRouterNavLink>
  );
};

export default NavLink;
