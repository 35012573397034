import { toSentenceCase } from '~/utils/functions';

export const MIN_LAWN_SIZE = 500;
export const MAX_LAWN_SIZE = 13500;

export const SOIL_TEST_DESCRIPTIONS = {
  organicMaterial:
    'This is the amount of materials derived from organisms in your soil. OM eventually breaks down into nutrients and gets absorbed by your grass and plays a critical role in the soil’s ecosystem. We like to see OM at a minimum of 2%; even higher for sandy soils.',
  cationExchange:
    'To oversimplify, this is the soil’s ability to retain water and nutrients. Lower CEC means the soil is less able to hold nutrients and vice versa. CEC is very difficult to change, however, you can change your lawn care regimen to fit your soil’s CEC.',
  soilPh:
    'pH impacts how easily grass can absorb certain nutrients. A perfect pH falls between 6.5-7.0; however, grass can easily tolerate anything between 4.0 - 9.0. Pursuing a perfect pH is usually unnecessary, expensive, and can take years.',
  solubleSalts:
    'Soluble salts assess the risk of fertilizer burn and pet spots; the higher the number, the greater the risk. Excluding pets and burn, there’s little to worry about when soluble salts are below 0.7. Above 3 can start to cause serious problems, depending on grass type.',
  phosphorus:
    'A key macronutrient involved in many cellular processes and more important for newly installed lawns. Also a major pollutant, apply with caution. 21 ppm is all the Phosphorus an established lawn needs.',
  potassium:
    "Another macronutrient that is vital to grass' ability to endure stress, however, it is often over-applied. Too much potassium increases the risk of certain diseases. 40 ppm is adequate for turfgrass.",
  calcium:
    "Crucial for frost-hardy plants, but is abundant in 99% of US soils. It's very important, but your soil probably has enough already (only 331 ppm is needed.)  Calcium can be helpful if trying to raise pH.",
  magnesium:
    'Critical nutrient for chlorophyll to function. 47 ppm is enough for grass, and only 1 out of 100 lawns have a magnesium deficiency.',
  sulfur:
    'Creates beneficial amino acids and aids in new growth. 7 ppm is all your lawn needs, however, higher levels can be helpful in preventing certain diseases. Excessive sulfur can cause problems in lawns that get easily waterlogged.',
  iron: 'Grass loves iron; weeds and diseases not so much. Iron will also give grass a darker color.  Below 75 ppm is considered deficient, but we’ll probably send you a little iron anyways to help prevent weeds and diseases.',
  boron:
    'The most micro of the micronutrients, as your lawn only needs 0.30 ppm to be healthy. Yet, about 15% of the lawns we see are short on Boron. It plays many roles, notably in building sugars. Use caution when adding Boron, as going over 5 ppm can cause major problems.',
  manganese:
    'Not to be confused with magnesium, manganese is needed for photosynthesis to work and is more sensitive to pH than other nutrients. About 5 ppm is sufficient across normal pH levels, and only about 2% of lawns have a deficiency.',
  copper:
    'Copper is important for photosynthesis and some proteins. 0.3-1 ppm is all you need. Given how expensive copper is, deficiencies should be addressed with compost as opposed to copper fertilizers.',
  zinc: 'Zinc is an important ingredient for most enzymes.  Your lawn needs only 0.5-1 ppm, and deficiencies are rare on most American lawns.',
};

export const LawnIssue = {
  WRONG_SIZE: 'WRONG_SIZE',
  PARTIAL_TREATMENT: 'PARTIAL_TREATMENT',
  ORDER_ANYWAY: 'ORDER_ANYWAY',
};

export const EcoRegion = {
  WARM_HUMID: 'wah',
  HUMID_TRANSITION: 'hut',
  COOL_HUMID: 'coh',
  WARM_ARID: 'waa',
  SEMIARID_TRANSITION: 'set',
  COOL_SEMIARID_PACIFIC: 'csp',
  COOL_SEMIARID: 'cos',
  COOL_HUMID_PACIFIC: 'chp',
  UNKNOWN: 'unk',
};

export const ecoRegionDisplay = (eco) => {
  const toDisplay = {
    [EcoRegion.WARM_HUMID]: 'Warm humid',
    [EcoRegion.HUMID_TRANSITION]: 'Humid transition',
    [EcoRegion.COOL_HUMID]: 'Cool humid',
    [EcoRegion.WARM_ARID]: 'Warm arid',
    [EcoRegion.SEMIARID_TRANSITION]: 'Semiarid transition',
    [EcoRegion.COOL_SEMIARID_PACIFIC]: 'Cool semiarid pacific',
    [EcoRegion.COOL_SEMIARID]: 'Cool semiarid',
    [EcoRegion.COOL_HUMID_PACIFIC]: 'Cool humid pacific',
    [EcoRegion.UNKNOWN]: 'Unknown',
  };

  return toDisplay[eco] || eco;
};

export const GrassTypeOverrides = {
  COOL: 'cool',
  WARM: 'warm',
  NONE: '',
};

export const grassTypeDisplay = (grassType) => {
  if (grassType === GrassTypeOverrides.NONE) {
    return 'None';
  }

  return toSentenceCase(grassType);
};

export const PestRegion = {
  ALASKA: 'ala',
  GREAT_LAKES: 'grl',
  HAWAII: 'haw',
  NORTHCENTRAL: 'noc',
  NORTHEAST: 'noe',
  NORTHWEST: 'now',
  SOUTHCENTRAL: 'soc',
  SOUTHEAST: 'soe',
  SOUTHWEST: 'sow',
};

export const pestRegionDisplay = (pest) => {
  const toDisplay = {
    [PestRegion.ALASKA]: 'Alaska',
    [PestRegion.GREAT_LAKES]: 'Great Lakes',
    [PestRegion.HAWAII]: 'Hawaii',
    [PestRegion.NORTHCENTRAL]: 'Northcentral',
    [PestRegion.NORTHEAST]: 'Northeast',
    [PestRegion.NORTHWEST]: 'Northwest',
    [PestRegion.SOUTHCENTRAL]: 'Southcentral',
    [PestRegion.SOUTHEAST]: 'Southeast',
    [PestRegion.SOUTHWEST]: 'Southwest',
  };

  return toDisplay[pest] || pest;
};

export const readableLawnIssueSelection = (lawn) => {
  if (!lawn || !lawn.lawnIssueSelection) {
    return null;
  }

  switch (lawn.lawnIssueSelection) {
    case LawnIssue.WRONG_SIZE:
      return 'Lawn size estimate is off';
    case LawnIssue.PARTIAL_TREATMENT:
      return 'Treat part of my lawn';
    case LawnIssue.ORDER_ANYWAY:
      return 'Order anyway';
    default:
      return lawn.lawnIssueSelection;
  }
};

export const MappingErrorReasonCodes = {
  CANT_FIND_LAWN: 'cant-find-lawn',
  OVERSIZED_LAWN: 'lawn-oversized',
  UNDERSIZED_LAWN: 'lawn-undersized',
  OTHER: 'other',
  NONE_RESOLVED: 'none-resolved',
};
