import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useMemo,
} from 'react';
import { animated, useTransition } from '@react-spring/web';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import Button from '~/components/buttons/button';

import styles from '~/components/dropdown/dropdown.module.scss';

export const DropdownContext = createContext();

const Dropdown = ({
  containerClassName,
  buttonContent,
  buttonClassName,
  menuClassName,
  alignMenu = 'left',
  children,
}) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    const handleCloseMenu = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    const handleClick = (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        handleCloseMenu(e);
      }
    };

    const handleKeydown = (e) => {
      if (e.key === 'Escape') {
        handleCloseMenu(e);
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen]);

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, transform: 'translateY(-2rem)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-2rem)' },
    config: {
      mass: 1,
      tension: 420,
      friction: 25,
    },
  });

  const ctx = useMemo(() => {
    const close = () => setIsOpen(false);

    return {
      close,
    };
  }, [setIsOpen]);

  return (
    <DropdownContext.Provider value={ctx}>
      <div
        ref={wrapperRef}
        className={cx(styles.container, containerClassName)}
      >
        <Button
          className={cx(styles.button, buttonClassName)}
          type="button"
          variant="outline"
          size="small"
          onClick={() => setIsOpen((o) => !o)}
          ref={buttonRef}
        >
          {buttonContent}
          <img
            className={styles.triangleIcon}
            src="/icons/triangle.svg?v1"
            alt=""
          />
        </Button>

        {transitions(
          (styleProps, item) =>
            item && (
              <animated.div style={styleProps}>
                <div
                  className={cx(menuClassName, styles.menu)}
                  style={{ [alignMenu]: 0 }}
                >
                  {children}
                </div>
              </animated.div>
            )
        )}
      </div>
    </DropdownContext.Provider>
  );
};

export default Dropdown;
