import React, { lazy } from 'react';

import NetworkError from '~/components/error/network-error';
import { retryPromise } from '~/utils/functions';

/**
 * Wrapper around React.lazy that will show a specific <NetworkError />
 * UI when the import fails. This avoids users seeing the full page
 * error message and instead they still see the app shell.
 */
export const safeLazy = (importFn) => {
  return lazy(() =>
    retryPromise(importFn).catch((error) => {
      return { default: () => <NetworkError error={error} /> };
    })
  );
};
