import React, { useState } from 'react';

import Button from '~/components/buttons/button';
import Tooltip from '~/components/tooltip/tooltip';

const Copyable = ({ value, children, allowWrap = false, style }) => {
  const [didCopy, setDidCopy] = useState(false);

  const handleClickCopy = () => {
    const input = document.createElement('input');

    input.type = 'text';
    input.name = 'foo';
    input.value = value || children;
    input.style.position = 'absolute';
    input.style.left = '-9999px';

    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    setDidCopy(true);

    setTimeout(() => {
      setDidCopy(false);
    }, 1000);
  };

  if (!children && !value) {
    return null;
  }

  return (
    <span
      style={{
        display: 'inline-block',
        position: 'relative',
        paddingRight: '4rem',
        whiteSpace: allowWrap ? 'wrap' : 'nowrap',
        wordBreak: allowWrap ? 'break-all' : null,
        ...style,
      }}
    >
      {children || value}

      <span
        style={{
          position: 'absolute',
          right: 0,
          top: '-0.2rem',
        }}
      >
        <Tooltip content="Copied" distance={10} isOpen={didCopy}>
          <Button
            type="button"
            variant="outline"
            size="small"
            onClick={handleClickCopy}
            style={{
              width: '3rem',
              padding: '0.5rem',
              lineHeight: '1',
              borderColor: 'var(--gray-5)',
              boxShadow: 'none',
            }}
          >
            <img
              style={{
                width: '1.5rem',
              }}
              src="/icons/clipboard.svg?v1"
              alt=""
            />
          </Button>
        </Tooltip>
      </span>
    </span>
  );
};

export default Copyable;
