export const QUESTIONNAIRE = {
  uuid: 'QUESTIONNAIRE-1',
  userUuid: 'USER-1',
  // I'd like to know when the questions were answered, so if another field is
  // more telling than give me that one. Otherwise I'll use the last updated timestamp.
  updatedAt: '2020-04-07',
  responses: [
    {
      questionUuid: 'QUESTION-1',
      questionText: 'Do you fertilize your lawn?',
      answerUuid: 'ANSWER-1',
      answerText: 'Nope',
    },
    {
      questionUuid: 'QUESTION-2',
      questionText: 'How old is your lawn?',
      answerUuid: 'ANSWER-5',
      answerText: '4+ years',
    },
  ],
};
