import queryString from 'query-string';

/**
 * Application routes are exposed as functions to allow dynamic values
 * to be passed in. Ex: `lawnShowRoute(123)` -> `/lawns/123`
 */

export const signInRoute = () => '/sign-in';
export const dashboardRoute = () => '/dashboard';

export const usersRoute = (queryParams) => {
  let path = '/users';

  if (queryParams) {
    path += '?' + queryString.stringify(queryParams, { arrayFormat: 'comma' });
  }

  return path;
};
export const userDetailsRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}`;
export const userEmailRedirectRoute = (email = ':email') =>
  `/users/email/${email}`;

export const lawnDetailsRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}`;

export const obligationsRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}/obligations`;

export const userShipmentsRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}/shipments`;
export const editUserShipmentsRoute = (
  userUuid = ':userUuid',
  shipmentUuid = ':shipmentUuid'
) => `/users/${userUuid}/shipments/${shipmentUuid}/edit`;

export const shipmentsRoute = (queryParams) => {
  let path = '/shipments';

  if (queryParams) {
    path += '?' + queryString.stringify(queryParams, { arrayFormat: 'comma' });
  }

  return path;
};

export const subscriptionsRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}/subscriptions`;
export const editSubscriptionRoute = (
  userUuid = ':userUuid',
  subscriptionUuid = ':subscriptionUuid'
) => `/users/${userUuid}/subscriptions/${subscriptionUuid}/edit`;
export const subscriptionHistoryRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}/subscriptions/history`;

export const mappingToolRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}/mapping-tool`;

export const storeItemsRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}/store-items`;

export const imageComparisonRoute = () => `/image-comparison`;

export const planTasksRoute = (userUuid = ':userUuid') =>
  `/users/${userUuid}/plan-tasks`;
