export const addressApi = (http) => {
  return {
    createShippingAddress({ userUuid, shippingAddress }) {
      return http.post(
        `/admin/users/${userUuid}/shipping_addresses/`,
        shippingAddress
      );
    },

    updateDefaultShippingAddress({ userUuid, addressUuid }) {
      return http.patch(
        `/admin/users/${userUuid}/shipping_addresses/${addressUuid}`
      );
    },

    updateShippingAddress: async ({
      userUuid,
      shippingAddressUuid,
      shippingAddress,
    }) => {
      const res = await http.put(
        `/admin/users/${userUuid}/shipping_addresses/${shippingAddressUuid}`,
        shippingAddress
      );
      return res;
    },

    deleteShippingAddress({ userUuid, addressUuid }) {
      return http.delete(
        `/api/users/${userUuid}/shipping_addresses/${addressUuid}`
      );
    },
  };
};
