import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import GeneralErrorContent from '~/components/error/general-error-content';
import { captureException } from '~/utils/exception-tracking';

import styles from '~/components/error/error.module.scss';

/**
 * Wrapper around react-error-boundary (https://github.com/bvaughn/react-error-boundary)
 * that has a default fallback component and sends errors to Sentry.
 *
 * @param {import('react-error-boundary').ErrorBoundaryProps} props
 */
const ErrorBoundary = (props) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      {...props}
      onError={(error, errorInfo) => {
        captureException(error, {
          extras: {
            location: 'errorBoundary',
            ...errorInfo,
          },
        });

        if (props.onError) {
          props.onError(error, errorInfo);
        }
      }}
    />
  );
};

export default ErrorBoundary;

function ErrorFallback({ error }) {
  return (
    <div className={styles.errorBody}>
      <GeneralErrorContent error={error} />
    </div>
  );
}
