import React, { useEffect } from 'react';

import { captureException } from '~/utils/exception-tracking';

import styles from './error.module.scss';
import buttonStyles from '~/components/buttons/button.module.scss';

const NetworkError = ({ error }) => {
  useEffect(() => {
    const message = error ? error.message : 'LazyLoadError';
    captureException(
      new LazyLoadError(message, {
        error,
        fingerprints: ['LazyLoadError'],
        level: 'info',
      })
    );
  }, [error]);

  const onClickReload = () => {
    window.location.reload();
  };

  return (
    <div
      className={['panel', styles.errorBody].join(' ')}
      style={{
        maxWidth: '48rem',
        margin: 'auto',
        height: 'auto',
      }}
    >
      <img className={styles.img} src="/images/smoking-mower.gif?v1" alt="" />

      <h1 className={styles.title}>shucks</h1>

      <section className={styles.section}>
        <p className={styles.copy}>
          There might have been a release since you were here last. Or you might
          have lost internet connectivity. Either way, reload the page to try
          again.
        </p>
      </section>

      <button
        className={[buttonStyles.button, buttonStyles.outline].join(' ')}
        onClick={onClickReload}
        type="button"
      >
        reload the page
      </button>
    </div>
  );
};

export default NetworkError;

class LazyLoadError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LazyLoadError';
  }
}
