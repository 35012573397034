import { atom, useAtom } from 'jotai';

import { api } from '~/api';
import { setExceptionTrackingUser } from '~/utils/exception-tracking';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import {
  getSavedSession,
  saveSession,
  deleteSession,
} from '~/features/session/session';

const sessionAtom = atom({
  token: null,
  refreshToken: null,
  user: null,
  isInitializing: true,
});

export const useSession = () => {
  const [session, setSession] = useAtom(sessionAtom);
  const { showFlashMessage } = useFlashMessage();

  const createSession = (session) => {
    setExceptionTrackingUser({ email: session.user.email });
    setSession(session);
  };

  const initSession = async () => {
    setSession((prev) => {
      return {
        ...prev,
        isInitializing: true,
      };
    });

    const session = await getSavedSession();
    if (session) {
      createSession(session);
    }

    setSession((prev) => {
      return {
        ...prev,
        isInitializing: false,
      };
    });
  };

  const signIn = async ({ email, password }) => {
    const session = await api.sessions.signIn({ email, password });
    const user = await api.users.findForSignIn(email, {
      headers: { Authorization: `Bearer ${session.token}` },
    });

    // TODO: remove the legacy permission check
    // once `isStaff` is returned from the API
    const hasLegacyPermission = user.admin;
    const hasNewPermission = user.isStaff;
    if (!hasLegacyPermission && !hasNewPermission) {
      throw new InsufficientPermissionError();
    }

    const sessionWithUser = {
      ...session,
      user,
    };
    createSession(sessionWithUser);
    await saveSession(sessionWithUser);
    return sessionWithUser;
  };

  const signOut = async () => {
    const session = await getSavedSession();
    await api.sessions.signOut(session.token);

    setSession({
      token: null,
      refreshToken: null,
      user: null,
    });

    showFlashMessage('You have been signed out', 'info');
    setExceptionTrackingUser(null);
    return deleteSession();
  };

  return {
    initSession,
    signIn,
    signOut,
    currentUser: session.user,
    isInitializing: session.isInitializing,
  };
};

export class InsufficientPermissionError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InsufficientPermissionError';
  }
}
