import React from 'react';

import NavLink from '~/components/navigation/nav-link';
import UserGroupIcon from '~/components/navigation/icons/user-group-icon';
import BoxIcon from '~/components/navigation/icons/box-icon';
import EnvironmentBanner from '~/components/environment-banner';
import { shipmentsRoute, usersRoute } from '~/routes';

import styles from './side-nav.module.scss';

const SideNav = () => {
  return (
    <aside className={styles.container}>
      <span>
        <img
          className={styles.logo}
          src="/images/garden-center-logo.svg?v1"
          alt="Sunday"
        />

        <EnvironmentBanner />
      </span>

      <nav className={styles.nav}>
        <NavLink
          className={styles.link}
          activeClassName={styles.activeLink}
          to={usersRoute()}
        >
          <UserGroupIcon className={styles.navIcon} />
          Users
        </NavLink>

        <NavLink
          className={styles.link}
          activeClassName={styles.activeLink}
          to={shipmentsRoute()}
        >
          <BoxIcon className={styles.navIcon} />
          Shipments
        </NavLink>
      </nav>

      <div>
        <div className={styles.externalLinkHeading}>Quick links</div>

        <div className={styles.externalLinksGroup}>
          <a
            className={styles.externalLink}
            href="https://goshippo.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ backgroundColor: '#673ab7' }}
          >
            Shippo
          </a>
          <a
            className={styles.externalLink}
            href="https://getsunday.com/shed"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'var(--dark-color)',
            }}
          >
            The Shed
          </a>
          <a
            className={styles.externalLink}
            href="https://trello.com/b/Pg4yUY2i/tech-issues"
            target="_blank"
            rel="noopener noreferrer"
            style={{ backgroundColor: '#0079bf' }}
          >
            Tech issues
          </a>
        </div>
      </div>
    </aside>
  );
};

export default SideNav;
