import queryString from 'query-string';

import { derivedShipmentDate } from '~/features/shipments/shipment';

export const shipmentsApi = (http) => {
  return {
    forUser: async (userUuid) => {
      const res = await http.get(
        `/api/shipment_orders/?user__uuid=${userUuid}`
      );

      const shipments = res.results;

      const byShipmentDateAsc = (a, b) => {
        return new Date(derivedShipmentDate(a)) <
          new Date(derivedShipmentDate(b))
          ? -1
          : 1;
      };

      return shipments.sort(byShipmentDateAsc);
    },

    create: async (shipment) => {
      const res = await http.post('/api/shipment_orders/', shipment);
      return res;
    },

    update: async (shipmentUuid, shipment) => {
      const res = await http.put(
        `/api/shipment_orders/${shipmentUuid}/`,
        shipment
      );
      return res;
    },

    delete: async (shipmentUuid) => {
      const res = await http.delete(`/api/shipment_orders/${shipmentUuid}/`);
      return res;
    },

    addItem: async (shipmentUuid, shipmentItem) => {
      const res = await http.post('/api/shipment_items/', {
        shipmentOrderUuid: shipmentUuid,
        quantity: shipmentItem.quantity,
        skuUuid: shipmentItem.skuUuid,
        isInsert: shipmentItem.isInsert,
      });
      return res;
    },

    updateItem: async (shipmentUuid, shipmentItem) => {
      const res = await http.put(`/api/shipment_items/${shipmentItem.uuid}/`, {
        shipmentOrderUuid: shipmentUuid,
        quantity: shipmentItem.quantity,
        skuUuid: shipmentItem.skuUuid,
      });
      return res;
    },

    removeItem: async (shipmentItemUuid) => {
      const res = await http.delete(`/api/shipment_items/${shipmentItemUuid}/`);
      return res;
    },

    shipmentSkus: async () => {
      const res = await http.get('/api/shipment_skus/');
      return res.sort((a, b) => (a.title < b.title ? -1 : 1));
    },

    needsAttentionOrders: async (queryParams) => {
      const [shipmentsRes, inventoryRes] = await Promise.all([
        http.get(`/api/shipment_orders/?${queryString.stringify(queryParams)}`),
        http.get(`/api/shipment_skus/inventory/`),
      ]);

      const inventoryBySkuID = inventoryRes?.reduce((acc, inventory) => {
        acc[inventory.skuId] = inventory.recentInventoryData;
        return acc;
      }, {});

      const shipments = shipmentsRes?.results.map((shipment) => {
        shipment.items.map((item) => {
          // `item.inventory` is an object with warehouseIDs as keys
          // and the inventory data as the value
          // { 357: { ... }, 441: { ... } }
          item.inventory = inventoryBySkuID[item.shipmentSku]?.reduce(
            (acc, inventory) => {
              acc[inventory.warehouseId] = inventory;
              return acc;
            },
            {}
          );

          return item;
        });

        return shipment;
      });

      return {
        ...shipmentsRes,
        results: shipments,
      };
    },

    shippingMethods: async () => {
      const res = await http.get('/admin/shipment_methods/');
      return res;
    },

    transmitShipment: async (shipmentUuid, shippingMethod) => {
      const res = await http.post(
        `/api/shipment_orders/${shipmentUuid}/transmit/`,
        {
          shippingMethod,
        }
      );
      return res;
    },

    split: async (shipmentUuid, splitShipments) => {
      return http.post(`/api/shipment_orders/${shipmentUuid}/split/`, {
        shipments: splitShipments,
      });
    },

    submitRma: async (payload) => {
      return http.post(`/api/obligations/replace/`, payload);
    },
  };
};
