import React from 'react';

import Button from '~/components/buttons/button';
import Copyable from '~/components/copyable';

import styles from './error.module.scss';

const GeneralErrorContent = ({ error }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src="/images/smoking-mower.gif?v1"
        alt="A lawn mower with smoke coming out"
      />

      <h1 className={styles.title}>well shit</h1>

      <section className={styles.section}>
        <p className={styles.copy}>
          Something went wrong. Feel free to try again, but if the issue
          persists take note of what you were doing and write up a ticket for
          the engineering team to investigate.
        </p>

        {error && (
          <div>
            <Copyable
              value={error.message}
              allowWrap
              style={{ display: 'block' }}
            >
              <p className={styles.copy}>
                <div className={styles.errorMessage}>
                  Error: {error.message}
                </div>
              </p>
            </Copyable>
          </div>
        )}
      </section>

      <Button
        className={styles.supportBtn}
        variant="outline"
        href="https://trello.com/b/Pg4yUY2i/tech-issues"
        target="_blank"
        rel="noopener noreferrer"
      >
        open tech issues board
      </Button>
    </div>
  );
};

export default GeneralErrorContent;
