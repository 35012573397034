const auth = {
  clientId: 'GRN8QPAOplpQb4EVNiQp9snF3CbxOidisgmsPIle',
  clientSecret:
    'yGTUFAaL78nlpjr7w2Rd0qHWSTWoTKAAtsUhMBI3EUygLKbp5O0lBGo1M2fl7S15yQLXaq1CCBaoc5adf2B0nTBBe3v5mdqXbvs1CYAMNybKjxVRKzZ4N3gELbUBWdVv',
};

const oAuthAuthorizationHeader = `Basic ${btoa(
  `${auth.clientId}:${auth.clientSecret}`
)}`;

export const sessionsApi = (http) => {
  return {
    signIn: ({ email, password }) => {
      const data = new URLSearchParams();
      data.append('grant_type', 'password');
      data.append('username', email);
      data.append('password', password);

      return http
        .post('/o/token/', null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: oAuthAuthorizationHeader,
          },
          body: data,
        })
        .then((res) => parseSession(res));
    },

    forgotPassword: ({ email }) => {
      return http.get(`/api/reset_password/${email}/`);
    },

    signOut: (token) => {
      const data = new URLSearchParams();
      data.append('token', token);

      return http.post('/o/revoke_token/', null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: oAuthAuthorizationHeader,
        },
        body: data,
      });
    },

    refresh: (refreshToken) => {
      const data = new URLSearchParams();
      data.append('grant_type', 'refresh_token');
      data.append('refresh_token', refreshToken);

      return http
        .post('/o/token/', null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: oAuthAuthorizationHeader,
          },
          body: data,
        })
        .then((res) => parseSession(res));
    },

    changePassword: ({ userId, oldPassword, newPassword }) => {
      return http.post('/api/change_password/', {
        userId,
        oldPassword,
        newPassword,
      });
    },
  };
};

function parseSession(sessionRes) {
  return {
    token: sessionRes.access_token,
    refreshToken: sessionRes.refresh_token,
  };
}
