import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { signInRoute } from '~/routes';
import { useSession } from '~/features/session/session-actions';

const AuthRoute = ({ children }) => {
  const location = useLocation();
  const { currentUser } = useSession();

  const redirectQueryString = queryString.stringify({
    redirect: location.pathname + location.search,
  });

  if (!currentUser) {
    return <Navigate to={`${signInRoute()}?${redirectQueryString}`} />;
  } else {
    return children;
  }
};

export default AuthRoute;
