import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { Form, Field } from '~/components/form';
import SearchField from '~/components/inputs/search-field';
import Dropdown from '~/components/dropdown/dropdown';
import DropdownItem from '~/components/dropdown/dropdown-item';
import SelectField from '~/components/inputs/select-field';
import { useSession } from '~/features/session/session-actions';
import { usersRoute } from '~/routes';
import { USER_SEARCH_KEYS } from '~/features/users/user';

import styles from '~/components/navigation/app-header.module.scss';

const FOCUS_SEARCH_INPUT_KEY = '/';

export const AppHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, signOut } = useSession();
  const queryParams = queryString.parse(location.search);
  const urlSearchBy =
    Object.keys(queryParams).find((queryParam) =>
      USER_SEARCH_KEYS.includes(queryParam)
    ) || 'email';
  const urlSearchTerm = queryParams[urlSearchBy];

  const handleSubmitSearch = async (formValues) => {
    navigate(usersRoute({ [formValues.searchBy]: formValues.searchTerm }));
  };

  // Focus the search input when the "/" key is pressed
  useEffect(() => {
    const searchInput = document.querySelector('input[name="searchTerm"]');

    const handleKeyDown = (e) => {
      const event = e;
      const formControlIsFocused = ['INPUT', 'SELECT', 'TEXTAREA'].includes(
        document.activeElement.nodeName
      );

      // Only focus if the user isn't filling out a form
      if (!formControlIsFocused && e.key === FOCUS_SEARCH_INPUT_KEY) {
        event.preventDefault();
        searchInput.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  if (!currentUser) {
    return null;
  }

  return (
    <header className={styles.container}>
      <Form
        className={styles.form}
        onSubmit={handleSubmitSearch}
        validate={() => ({})}
        initialValues={{ searchTerm: urlSearchTerm, searchBy: urlSearchBy }}
      >
        {() => (
          <>
            <div className={styles.searchByWrapper}>
              <label className={styles.searchByLabel} htmlFor="searchBy">
                search by
              </label>

              <Field
                className={styles.searchByField}
                name="searchBy"
                component={SelectField}
                autoComplete="off"
              >
                <option value="email">Email address</option>
                <option value="shipping_phone_number">Phone number</option>
                <option value="name">Name</option>
                <option value="user_slug">User slug</option>
                <option value="lawn_address">Lawn address</option>
              </Field>
            </div>

            <Field
              label="search"
              name="searchTerm"
              type="search"
              component={SearchField}
              autoComplete="off"
            />
          </>
        )}
      </Form>

      <Dropdown
        alignMenu="right"
        buttonClassName={styles.dropdownButton}
        buttonContent={
          <div className={styles.dropdownButtonInner}>
            <img
              className={styles.avatarImg}
              src="/icons/user-solid-circle.svg?v1"
              alt=""
            />
            <span className={styles.dropdownButtonText}>
              {currentUser.email}
            </span>
          </div>
        }
      >
        <DropdownItem icon="/icons/stand-by.svg?v1" onClick={signOut}>
          Sign out
        </DropdownItem>
      </Dropdown>
    </header>
  );
};

export default AppHeader;
