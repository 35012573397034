import React, {
  useCallback,
  useMemo,
  useReducer,
  useEffect,
  useRef,
} from 'react';

import { useIsMounted } from '~/hooks/use-is-mounted';
import { shallowEqualObjects, trimValues } from '~/utils/functions';

export const FormContext = React.createContext();

const initialState = {
  isSubmitting: false,
  submitFailed: false,
  formValues: {},
  formErrors: {},
};

const Form = ({
  onSubmit,
  validate,
  initialValues = {},
  children,
  resetOnSubmit,
  ...rest
}) => {
  const isMounted = useIsMounted();

  const prevInitialValues = useRef(initialValues);
  const [state, realDispatch] = useReducer(reducer, {
    ...initialState,
    formValues: initialValues,
  });

  // Wrap dispatch so we only call it when the <Form /> is mounted
  const dispatch = useCallback(
    (action) => {
      if (!isMounted.current) {
        return;
      }

      return realDispatch(action);
    },
    [isMounted]
  );

  // Update form values when props.initialValues changes
  useEffect(() => {
    if (!shallowEqualObjects(prevInitialValues.current, initialValues)) {
      dispatch({
        type: 'SET_FIELD_VALUES',
        formValues: initialValues,
      });

      prevInitialValues.current = initialValues;
    }
  }, [initialValues, dispatch, isMounted]);

  // We need a dedicated onChange function vs using `dispatch` so we can run validation
  const handleFieldChange = useCallback(
    (name, value) => {
      dispatch({
        type: 'CHANGE_FIELD',
        name,
        value,
        formErrors: validate({ ...state.formValues, [name]: value }),
      });

      /**
       * TODO: investigate if there's a performance win to be had by
       * moving the `validate()` call to a `requestIdleCallback` and
       * dispatching a second event with the errors.
       */
    },
    [dispatch, validate, state.formValues]
  );

  const handleError = useCallback(
    (errors) => {
      dispatch({ type: 'SUBMIT_FAILED', errors });

      /**
       * Eww.
       *
       * This focuses the "first" field with an error.
       * "First" means the first field in the document flow,
       * so this will break with wonky layouts.
       */
      setTimeout(() => {
        const errorField = document.querySelector('[data-valid="false"]');
        if (errorField) {
          errorField.focus();
          errorField.scrollIntoView({
            block: 'center',
          });
        }
      }, 0);
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      dispatch({ type: 'SUBMIT_START' });

      const errors = validate(state.formValues);
      for (const fieldName in errors) {
        if (isErrorValue(errors[fieldName])) {
          handleError(errors);
          return;
        }
      }

      onSubmit(trimValues(state.formValues))
        .then((errors) => {
          if (errors) {
            handleError(errors);
          } else {
            dispatch({ type: 'SUBMIT_SUCCESSFUL', shouldReset: resetOnSubmit });
          }
        })
        .catch((err) => {
          handleError();
        });
    },
    [dispatch, handleError, onSubmit, resetOnSubmit, state.formValues, validate]
  );

  const ctx = useMemo(() => {
    return {
      state,
      dispatch,
      handleFieldChange,
      handleSubmit,
    };
  }, [state, dispatch, handleFieldChange, handleSubmit]);

  return (
    <FormContext.Provider value={ctx}>
      <form noValidate {...rest} onSubmit={handleSubmit}>
        {children(state, handleFieldChange)}
      </form>
    </FormContext.Provider>
  );
};

export default Form;

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_FIELD':
      const newFormValues = {
        ...state.formValues,
        [action.name]: action.value,
      };

      return {
        ...state,
        formValues: newFormValues,
        formErrors: action.formErrors,
      };
    case 'SET_FIELD_VALUES':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.formValues,
        },
      };
    case 'SET_FIELD_ERRORS':
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...action.formErrors,
        },
      };
    case 'SUBMIT_START':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'SUBMIT_FAILED':
      return {
        ...state,
        submitFailed: true,
        isSubmitting: false,
        formErrors: {
          ...state.formErrors,
          ...action.errors,
        },
      };
    case 'SUBMIT_SUCCESSFUL':
      return {
        ...state,
        formValues: action.shouldReset ? {} : state.formValues,
        submitFailed: false,
        isSubmitting: false,
      };
    default:
      throw new Error(
        `Forgot to handle ${action.type} actions in form reducer`
      );
  }
}

/**
 * Given a value from the validation object determine
 * if it should be considered an error. Typically this can
 * be determined if the value is truthy, but in the case
 * of an array we need to check each item's values
 */
function isErrorValue(value) {
  if (Array.isArray(value)) {
    return value.some((obj) => {
      return Object.values(obj).some(Boolean);
    });
  }

  return Boolean(value);
}
