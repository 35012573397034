const NEW_UUID_REGEX = new RegExp(/^NEW:.*/);

/**
 * shipment.itemType as defined by the API
 */
export const ShipmentItemType = {
  SUBSCRIPTION: 'subscription', // related to a subscription
  CUSTOM: 'custom', // created by CS
  ADD_ON: 'add-on', // standard add-on
};

export const isCreatedShipmentItem = (shipmentItem) => {
  return NEW_UUID_REGEX.test(shipmentItem.uuid);
};

export const canEditShipmentItem = (shipmentItem) => {
  const isSubscription =
    shipmentItem.itemType === ShipmentItemType.SUBSCRIPTION;
  return !isSubscription;
};

export const canSelectShipmentSku = (shipmentSku) => {
  return shipmentSku.visibleAdmin;
};
