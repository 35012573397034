import { sessionsApi } from '~/api/sessions-api';
import { usersApi } from '~/api/users/users-api';
import { createHttpClient } from '~/utils/http';
import { getSavedSession } from '~/features/session/session';
import { lawnsApi } from './lawns-api';
import { ordersApi } from './orders-api';
import { shipmentsApi } from './shipments-api';
import { questionnaireApi } from './questionnaire-api';
import { subscriptionsApi } from './subscriptions-api';
import { eventsApi } from './events-api';
import { storeItemsApi } from '~/api/store-items-api';
import { paymentsApi } from '~/api/payments-api';
import { obligationsApi } from '~/api/obligations-api';
import { productsApi } from '~/api/products-api';
import { planTasksApi } from '~/api/plan-tasks-api';
import { addressApi } from '~/api/address-api';

export const createApi = ({ subsoilHttpClient }) => {
  return {
    events: eventsApi(subsoilHttpClient),
    lawns: lawnsApi(subsoilHttpClient),
    obligations: obligationsApi(subsoilHttpClient),
    orders: ordersApi(subsoilHttpClient),
    payments: paymentsApi(subsoilHttpClient),
    planTasks: planTasksApi(subsoilHttpClient),
    products: productsApi(subsoilHttpClient),
    questionnaire: questionnaireApi(subsoilHttpClient),
    sessions: sessionsApi(subsoilHttpClient),
    shipments: shipmentsApi(subsoilHttpClient),
    storeItems: storeItemsApi(subsoilHttpClient),
    subscriptions: subscriptionsApi(subsoilHttpClient),
    users: usersApi(subsoilHttpClient),
    address: addressApi(subsoilHttpClient),

    setErrorListener: (cb) => {
      subsoilHttpClient.errorListeners.push(cb);
    },

    http: subsoilHttpClient,
  };
};

const subsoilHttpClient = createHttpClient({
  baseUrl: window.env.API_URL,
  requestTransformers: [
    async (request) => {
      const session = await getSavedSession();

      if (!session || !session.token) {
        return request;
      }

      return {
        ...request,
        headers: {
          Authorization: `Bearer ${session.token}`,
          ...request.headers,
        },
      };
    },
  ],
});

const gardenCenterHttpClient = createHttpClient({
  baseUrl: '',
});

export const api = createApi({ subsoilHttpClient, gardenCenterHttpClient });

(window.gardenCenter = window.gardenCenter || {}).api = api;
